import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';
// import htmx from './htmx';
import htmx from 'htmx.org';
// import filterDropdown from './ts/filter-dropdown';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const inputField = document.getElementById('search') as HTMLInputElement | null;
    const resetButton = document.getElementById('reset-button') as HTMLElement | null;
    const searchIcon = document.querySelector('.search-icon') as HTMLElement | null;

    // check if inputField is empty
    if (inputField?.value.trim() === '') {
        // Input field is empty
        // Hide reset button
        if (resetButton && !resetButton.classList.contains('hidden')) {
            resetButton.classList.add('hidden');
        }
        // Show search icon
        if (searchIcon && searchIcon.classList.contains('hidden')) {
            searchIcon.classList.remove('hidden');
        }
    } else {
        // Input field has a value
        // Show reset button
        resetButton?.classList.remove('hidden');

        // hide search icon
        if (searchIcon && !searchIcon.classList.contains('hidden')) {
            searchIcon.classList.add('hidden');
        }
    }

    htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something

        // check if value changed
        const inputField = document.getElementById('search') as HTMLInputElement | null;
        const resetButton = document.getElementById('reset-button') as HTMLElement | null;
        const searchIcon = document.querySelector('.search-icon') as HTMLElement | null;

        // check if inputField is empty
        if (inputField?.value.trim() === '') {
            // Input field is empty
            // Hide reset button
            if (resetButton && !resetButton.classList.contains('hidden')) {
                resetButton.classList.add('hidden');
            }
            // Show search icon
            if (searchIcon && searchIcon.classList.contains('hidden')) {
                searchIcon.classList.remove('hidden');
            }
        } else {
            // Input field has a value
            // Show reset button
            const resetButtonTimeout = setTimeout(() => {
                resetButton?.classList.remove('hidden');
                clearTimeout(resetButtonTimeout);
            }, 100);
            // hide search icon
            if (searchIcon && !searchIcon.classList.contains('hidden')) {
                searchIcon.classList.add('hidden');
            }
        }

        const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
        if (allSwiper.length) {
            import('./ts/swiper').then((swiper) => {
                allSwiper.forEach(function (swiperElement) {
                    if (!swiperElement.dataset.swiperOptions) return;
                    // Set swiper element and swiper options from data-attribute
                    swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
                });
            });
        }

        const filterCategories = document.querySelectorAll('.filter-categories') as NodeListOf<HTMLElement>;
        if (filterCategories.length) {
            import('./ts/filter-dropdown').then((filter) => {
                filterCategories.forEach((container) => {
                    filter.default.init(container);
                });
            });
        }
    });

    const filterCategories = document.querySelectorAll('.filter-categories') as NodeListOf<HTMLElement>;
    if (filterCategories.length) {
        import('./ts/filter-dropdown').then((filter) => {
            filterCategories.forEach((container) => {
                filter.default.init(container);
            });
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const youtubeVideoContainers = document.querySelectorAll('.youtube-video-container') as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    const spotifyContainers = document.querySelectorAll('.spotify-container') as NodeListOf<HTMLElement>;
    if (spotifyContainers.length) {
        import('./ts/spotify').then((spotify) => {
            spotifyContainers.forEach((container) => {
                spotify.default.init(container);
            });
        });
    }

    const heroHome = document.querySelector('.hero-home') as HTMLElement;
    if (heroHome) {
        import('./ts/hero-video').then((heroVideo) => {
            heroVideo.default.init(heroHome);
        });
        import('./ts/hero-overlay').then((heroOverlay) => {
            heroOverlay.default.init(heroHome);
        });
    }

    const eventSliderSections = document.querySelectorAll('.event-slider-section') as NodeListOf<HTMLElement>;
    if (eventSliderSections.length) {
        import('./ts/event-slider').then((eventSlider) => {
            eventSliderSections.forEach((section) => {
                eventSlider.default.init(section);
            });
        });
    }
})();
